<template>
  <div class="Site PageBox">
    <van-nav-bar
      fixed
      :border="false"
      :title="$t('note[0]')"
      left-arrow
      @click-left="$router.go(-1)"
      @click-right="$router.push('/user/notelist')"
    >
      <van-icon name="./static/icon/task00.png" slot="right" />
    </van-nav-bar>

    <div class="ScrollBox" v-if="pageState==0">
      <div class="noteitem">
        <div style="padding: 10px">{{$t("note[1]")}}</div>
        <van-field required
                   class="input" :border="false"
                   v-model="noteData.phone"
                   :placeholder="$t('note[2]')"
        />
      </div>
      <div class="noteitem">
        <div style="padding: 10px">{{$t("note[4]")}}</div>
        <van-field type="textarea" rows="5"
                   class="input" :border="false" style="height: 150px"
                   v-model="noteData.note"
                   :placeholder="$t('note[5]')"
        />
      </div>
      <div class="noteitem">
        <div style="padding: 10px">
          {{$t("note[6]")}}
        </div>
        <div class="mygrid22" style="text-align: center">
          <van-uploader v-model="noteData.screenshots" max-count="1"
                        :after-read="afterRead"/>
        </div>
      </div>
      <div style="margin: 0px auto;width: 90%;text-align: center;margin-top: 30px">
        <van-button color="#DAA78A" size="large" style=""
                    @click="submitNote"
        ><span style="color:#FFF">{{$t('bindAccount.default[1]')}}</span>
        </van-button>
      </div>
    </div>
    <div class="completePanel" v-else>
      <div>
        <img src="../../../public/static/icon/btn_done.png" style="width:50px"/>
      </div>
      <div style="color: #e7e7e7;font-size: 22px">{{$t('note[8]')}}</div>
      <div>{{$t('note[9]')}}</div>
      <div style="margin: 10px auto;">
        <van-button block color="#DAA78A" to="/user/notelist">
          <span style="font-size: 18px">{{ $t('note[10]') }}</span>
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Info',
  components: {
  },
  props: ['articleType','articleId'],
  data() {
    return {
      pageState:0,
      noteData: {
        phone: '',
        note:'',
        screenshots:[]
      },
    }
  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  mounted() {

  },
  activated() {

  },
  destroyed() {
    
  },
  methods: {
    submitNote(){
      if (this.noteData.phone.length<=0){
        this.$Dialog.Toast(this.$t('note[3]'),'center')
        return
      }
      if (this.noteData.note.length<=0){
        this.$Dialog.Toast(this.$t('note[5]'))
        return
      }
      if (this.noteData.screenshots.length<=0){
        this.$Dialog.Toast(this.$t('note[7]'))
        return
      }

      this.$Model.addNote(this.noteData,data=>{
        let msg = '';
        switch (data.code) {
          default:
            if (data.code_dec){
              msg = data.code_dec;
            }
            break;
        }
        if (msg.length>0){
          this.$Dialog.Toast(msg)
        }

        if(data.code==1){
          this.pageState = 1;
        }
      })
    },
    afterRead(file) {
      file.status = 'uploading'
      file.message = this.$t('upload[0]')
      this.uploadImgs(file)
    },
    uploadImgs(file) {
      if (!file.file.type.match(/image/)) {
        file.status = "failed";
        file.message = this.$t("upload[1]");
        return;
      }
      console.log(2);
      this.compressImg(file);
    },
    compressImg(file) {
      this.$Util.CompressImg(file.file.type, file.content, 750, (image) => {
        let param = new FormData();
        param.append("token", localStorage["Token"]);
        param.append("type", 3);
        param.append("image", image, file.file.name);
        this.$Model.UploadImg(param, (data) => {
          if (data.code == 1) {
            file.message = this.$t("upload[2]");
            file.status = "success";
            file.url = data.url;
            // this.submitTask();
            //显示提现弹窗
            console.log("upload success");
          } else {
            file.status = "failed";
            console.log("upload false");
            // file.message = this.$t("upload[3]");
          }
        });
      });
    },
  }
}
</script>
<style scoped>
.Content>>>img{
  max-width: 100%;
}
.PageBox {
  color: #8AC0C0;
  background-color: #0F4F4E;
}

.van-nav-bar {
  background-color: #0F4F4E;
}
.van-nav-bar>>>.van-nav-bar__title{
  color:#FFFEFF;
}
.van-nav-bar>>>.van-nav-bar__arrow {
  color:#FFFEFF;
  font-size: 20px;
}

.completePanel{
  font-size: 17px;
  margin:20px auto;width:80%;text-align: center;line-height: 30px;margin-top: 40px;
}
.van-cell--borderless.input {
  background-color: #0D4947;
  border-radius:  5px;
  overflow: hidden;
  margin: 0 auto;
  padding: 0 30px 0 13px;
  height: 45px;
  width: 95%;
}

  .noteitem {
    width:95%;
    margin: 0 auto;
    background-color: #337F7B;
    border-radius: 5px;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
</style>